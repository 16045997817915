<template>
  <div class="planning">
  <header class="inner-head">
            <h2 class="inner-head-title">Long Term Planning</h2>

      <ul class="actions right">

                <li>
         <button
            type="button"
           class="action-button hover:opacity-75 mt-1"
            @click="editGrid()"
          >
         <i class="fas fa-highlighter"></i>
           Edit</button>
        </li>
      </ul>

    </header>

   <planningList v-if="isMobile"></planningList>
      <planningGrid v-else></planningGrid>
      
      
        </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  calendarYearList,
} from "@/assets/js/utils";

const planningGrid = () => import("@/components/planning/ThePlanningGrid.vue");
const planningList = () => import("@/components/planning/ThePlanningList.vue");

export default {
  name: "planning",
  props: [],
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    isMobile() {
      return screen.width < 994
    },
    calendarDropdown() {
      return this.calendarList;
    },
  },
 data() {
    return {
      calendarList: calendarYearList(),
      selectedCalendar: {},
    };
  },
  created() {
    this.selectedCalendar = this.calendarList[0];
  },
  mounted() {
    
  },
  methods: {
    editGrid() {
      this.$router.push({ name: "PlanningEdit"});
      window.scrollTo(0, 0);
    },

  },
  components: {
    // noaccess,
    planningGrid,
    planningList,
  },
};
</script>

<style scoped>

</style>
